import { graphql, Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { PageH1 } from '../components/PageH1'
import { WidthWrapper } from '../components/WidthWrapper'
import { FourOhFourPageQuery } from '../types/graphql'
import { cx } from '../utils/helpers'

export const query = graphql`
  query FourOhFourPageQuery {
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`

type Props = {
  data: FourOhFourPageQuery
}

export default function FourOhFourPage({ data }: Props): React.ReactElement {
  return (
    <>
      <Helmet>
        <title>
          {`Page not found${data.datoCmsSite!.globalSeo!.titleSuffix}`}
        </title>
      </Helmet>

      <PageH1 subtitle="Sorry, but the page you were looking for couldn’t be found.">
        Page not found
      </PageH1>

      <WidthWrapper className="text-accent">
        <Link
          to="/"
          className={cx(
            'inline-block px-4 pt-2 pb-3 rounded shadow-md',
            'bg-accent text-white text-center hover:bg-brand focus:bg-brand',
            'outline-none focus:shadow-outline focus:outline-none',
          )}
        >
          Back to homepage
        </Link>
      </WidthWrapper>
    </>
  )
}
